import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { StaticImage } from "gatsby-plugin-image";
import thailand from "~/images/thailand-bike-tour-4.jpeg";
//import vietnam from "~/images/vietnam-review.png";
import cambodia from "~/images/east-hokkaido-cycling-east-coast.jpeg";
import community from "~/images/branded-abt1.jpeg";
import members from "~/images/jordan-cycling.jpeg";
import guides from "~/images/cambodia-bike-tour-5.jpeg";
import price from "~/images/noto-day4.jpeg";
import kit from "~/images/pankaj-mangal.jpeg";
import newtrips from "~/images/cambodia-bike-tour-1.jpeg";
import StripePricingTable from "~/components/StripePricingTable";

const MembersOnly = () => {
	const features = [
		{
			id: 1,
			headingText: "1. Vibrant Community",
			reviewText:
				"Connect with kindred spirits from around the world who share your love for cycling. Forge lasting friendships as you pedal together, exchange stories, and create cherished memories during our thrilling group rides.",
			//author: "Tom & group, US",
			imageSrc: community,
			altText: "community",
		},
		{
			id: 2,
			headingText: "2. Exclusive Members-only Rides*",
			reviewText:
				"Explore exclusive, off-beat destinations on curated rides at subsidized prices, designed solely for our valued members. Uncover hidden wonders and enjoy an extraordinary biking journey with like-minded enthusiasts.",

			imageSrc: cambodia,
			altText: "members",
		},
		// {
		// 	id: 3,
		// 	headingText: "Off-beat Destinations",
		// 	reviewText:
		// 		"Leave the beaten path behind and embrace the road less traveled. As a member, you'll unlock access to awe-inspiring off-beat destinations. Our curated journeys reveal the world's best-kept secrets, all while nurturing the camaraderie of like-minded adventurers. ",

		// 	imageSrc: members,
		// 	altText: "Off-beat Destinations",
		// },
		// {
		// 	id: 4,
		// 	headingText: "Members-only Price",
		// 	reviewText:
		// 		"As a valued member, benefit from subsidized prices, offering excellent value for your cycling adventures. Enjoy exclusive pricing for members-only ride, along with a 5% discount on all our group departure trips that are published on our website. Please note, the discount is valid only on our existing group departures that are booked at least 6 months prior to departure.",

		// 	imageSrc: price,
		// 	altText: "Members-only Price",
		// },
		{
			id: 4,
			headingText: "3. Exclusive Discounts*",
			reviewText:
				"As a valued member, enjoy exclusive 5% off on the trip price across our group departures (except self-guided tours) listed on our website, offering excellent value for your cycling adventures. Please note, the discount is applicable exclusively to group departures, booked at least 6 months prior to departure. This discount is not valid on add-ons.",

			imageSrc: price,
			altText: "Members-only Price",
		},

		{
			id: 5,
			headingText: "4. Complimentary E-bike Upgrade*",
			reviewText:
				"Embrace the freedom of cycling with e-bike option for those who prefer a little assistance, ensuring everyone can enjoy riding together. We offer a complimentary e-bike upgrade (except Nepal and self-guided tours) to all our members, subject to availability. Please note, the complimentary e-bike upgrade is applicable exclusively to group departures, booked at least 6 months prior to departure. If you prefer not to use an e-bike, you'll receive an equivalent additional discount of 5% as a non-e-biker.",

			imageSrc: thailand,
			altText: "E-bike Upgrade",
		},
		// {
		// 	id: 6,
		// 	headingText: "5. Early-access to New Trips",
		// 	reviewText:
		// 		"Enjoy early access to our latest trips, granting you the privilege to set out on exciting adventures before they are made available to the general public, and receive a generous 10% discount on all our new group trips.",

		// 	imageSrc: newtrips,
		// 	altText: "Early-access to New Trips",
		// },

		{
			id: 7,
			headingText: "5. Top Guides",
			reviewText:
				"Experience the best on members-only rides with our seasoned ride leaders who ensure your safety, share local anecdotes, provide guidance, and create a supportive environment.",
			imageSrc: guides,
			altText: "Top Guides",
		},

		{
			id: 8,
			headingText: "6. Exclusively Designed Cycling Kit",
			reviewText:
				"Wear your pride as a Silver Rider with our exclusively designed cycling kit for members-only rides, showcasing your membership in style.",

			imageSrc: kit,
			altText: "Exclusively Designed Cycling Kit",
		},
	];
	return (
		<Layout>
			<GatsbySeo
				title="Membership Silver Riders Club"
				description="Exclusive Members-only rides in breathtaking off-beat destinations."
			/>
			<section className="relative lg:pb-16 xl:pb-16 bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="max-w-7xl mx-auto">
					<div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<div className="mt-10 mx-auto lg:mx-0 max-w-xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
							<div className="sm:text-center  lg:text-left">
								<h1 className=" text-gray-900 lg:text-3xl  xl:text-4xl">
									<span className="block   xl:inline font-normal">Membership</span>{" "}
									<span className="block text-primary xl:inline font-semibold"></span>{" "}
									<span className="block text-primary  xl:inline font-normal">
										Silver Riders Club
									</span>{" "}
								</h1>

								<p className="mt-3 mb-10 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
									Exclusive Members-only rides in breathtaking off-beat destinations at
									special prices.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:absolute lg:pl-8 lg:inset-y-0 lg:right-0 lg:w-1/2 ">
					<div className="relative">
						<StaticImage
							className="rounded-lg shadow-md transform border border-gray-200"
							src="../images/vietnam-review.png"
							alt="cycling adventures"
							layout="FULL_WIDTH"
							aspectRatio="1.2"
							loading="lazy"
						/>
					</div>
				</div>
			</section>
			<section className="my-20">
				<h2 className="mx-auto text-center">About Silver Riders Club</h2>
				<div className="">
					<p className="prose prose-sm sm:prose lg:prose-lg  mb-6 mx-auto mt-10">
						Are you ready to elevate your cycling experience to new heights? Look no
						further than the Silver Riders Club - a global riding community, infused
						with the warmth of a close-knit local family. Experience the extraordinary
						as we invite you to join our exclusive members-only rides. Experience the
						joy of cycling in the most stunning off-beat destinations with like-minded
						individuals in a welcoming and supportive community.
					</p>
					{/* <p className="prose prose-sm sm:prose lg:prose-lg italic  mb-6 mx-auto">
						"We're creating a unique type of active holiday company – formed by its
						members, rather than investors, and driven by mutual accountability, not
						shareholders."
					</p> */}
					{/*
					<h2 className="mx-auto mt-20 mb-10 capitalize text-center">
						Join the Community
					</h2>
					<p className="prose prose-sm sm:prose lg:prose-lg mb-6 mx-auto">
						The introductury Silver Riders Club joining fee is US $50 per person per
						year. Enroll now!
					</p>
					<StripePricingTable />
					*/}
					{/* <div className="flex justify-center items-center">
						<button className="bg-primary2 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-full">
							<a
								href="https://rzp.io/i/oD7PLEtwWy"
								target="_blank"
								rel="noopener noreferrer nofollow"
								className="text-white"
							>
								Join the Community
							</a>
						</button>
					</div> */}
					{/* <p className=" text-xl mt-20 max-w-2xl text-center mx-auto sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-medium">
						"We're creating a unique type of cycling holiday company – formed by its
						members, rather than investors, and driven by mutual accountability, not
						shareholders." <br />
						<span className="text-lg">- Pankaj Mangal, Founder</span>
					</p> */}
				</div>
				<div className=" mt-28 ">
					<h2 className="mx-auto capitalize text-center mb-4">Join the Community</h2>
					<p className="prose prose-sm sm:prose lg:prose-lg mb-6 mx-auto">
						The introductury Silver Riders Club joining fee is{" "}
						<span className="font-semibold">US $75 per person per year</span>.{" "}
						<br></br>
						<span className="">No commitments, cancel anytime. Enroll now!</span>
					</p>
					<StripePricingTable />
				</div>
				<h2 className="mt-32">The 6 Key Features</h2>
				{/* features */}
				{features.map((feature) => (
					<div className="flex">
						<div key={feature.id} className=" my-12 lg:flex mr-4">
							<div className="relative lg:w-1/2 ">
								<img
									className="rounded-lg shadow-lg transform"
									src={feature.imageSrc}
									alt="cycling adventures"
									// layout="FULL_WIDTH"
									//aspectRatio="1.3"
									// loading="lazy"
								/>

								{/* <div className="w-32 lg:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-bgtrust rounded-full">
									<svg
										width="24"
										height="24"
										viewbox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z"
											fill="#125960"
										></path>
									</svg>
								</div> */}
							</div>
							<div className="lg:w-1/2 xl:ml-32 lg:ml-20 lg:mt-0 mt-4 flex flex-col justify-between">
								<div>
									<h3 className="font-medium xl:leading-loose text-gray-800">
										{feature.headingText}
									</h3>
									<p className="leading-6 mt-4 font-light text-gray-600">
										{feature.reviewText}
									</p>
									<p className="font-medium leading-4 text-gray-800 my-8">
										{feature.author}
									</p>
								</div>
							</div>
						</div>
					</div>
				))}
				<div className="bg-gray-50 rounded-lg py-8 mt-20">
					<p className=" text-xl max-w-2xl text-center mx-auto sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-medium">
						"We're creating a unique type of cycling holiday company – formed by its
						members, rather than investors, and driven by mutual accountability, not
						shareholders." <br />
						<span className="text-lg">- Pankaj Mangal, Founder</span>
					</p>
				</div>

				<div className=" mt-28 ">
					<h2 className="mx-auto capitalize text-center mb-4">Join the Community</h2>
					<p className="prose prose-sm sm:prose lg:prose-lg mb-6 mx-auto">
						The introductury Silver Riders Club joining fee is{" "}
						<span className="font-semibold">US $75 per person per year</span>.{" "}
						<br></br>
						<span className="">No commitments, cancel anytime. Enroll now!</span>
					</p>
					<StripePricingTable />
				</div>
				<div className="mx-auto mt-10 mb-4 prose">
					<h3>* Terms and Conditions</h3>
					<ul>
						<li>
							The exclusive discount of 5% (except self-guided tours) and complimentary
							e-bike upgrade (except Nepal and self-guided tours) is applicable
							exclusively to group departures. Bookings must be made at least 6 months
							before the departure date to avail it. This discount is not valid on
							add-ons.
						</li>
						<li>
							Members-only rides are offered at subsidized prices and hence, can't be
							combined with any other discount offer.
						</li>
						<li>
							Membership benefits are not applicable when bookings are made through a
							travel advisor.
						</li>
						<li>
							We reserve the right to change the membership fee for both existing and
							new sign-ups at any time.
						</li>
					</ul>
				</div>
				{/* <div className="flex justify-center items-center my-20">
					
					<button className="bg-primary2 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-full">
						<a
							href="https://rzp.io/i/oD7PLEtwWy"
							target="_blank"
							rel="noopener noreferrer nofollow"
							className="text-white"
						>
							Join the Community
						</a>
					</button>
				</div> */}
			</section>
		</Layout>
	);
};

export default MembersOnly;
