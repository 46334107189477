import React, { useEffect } from "react";

const StripePricingTable = () => {
	useEffect(() => {
		// Dynamically add the Stripe script to the document
		const stripeScript = document.createElement("script");
		stripeScript.src = "https://js.stripe.com/v3/";
		stripeScript.async = true;
		document.head.appendChild(stripeScript);

		stripeScript.onload = () => {
			// Once the Stripe script is loaded, dynamically add the pricing table script
			const pricingTableScript = document.createElement("script");
			pricingTableScript.src = "https://js.stripe.com/v3/pricing-table.js";
			pricingTableScript.async = true;
			document.body.appendChild(pricingTableScript);
		};

		// Cleanup: Remove scripts on component unmount
		return () => {
			document.head.removeChild(stripeScript);
			document.body.removeChild(pricingTableScript);
		};
	}, []);

	return (
		<stripe-pricing-table
			pricing-table-id="prctbl_1PitW4LMykFI4KVRTynewISs"
			publishable-key="pk_live_oJJJSixAREEcRCogUvrTJvow00E4rU9CuE"
			// pricing-table-id="prctbl_1OGITiLMykFI4KVR2Gd9d16f"
			// publishable-key="pk_live_oJJJSixAREEcRCogUvrTJvow00E4rU9CuE"
		></stripe-pricing-table>
	);
};

export default StripePricingTable;
